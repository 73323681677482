<template>
	<div class="pageDetails">
		<div class="page">
		<div class="page-item">
			<div class="create">
				<!-- <el-button plain type="normal" size="mini" @click="close"
					>返回
				</el-button> -->
				<el-button
					v-if="!$route.query.buttonShow && auditStatus == 1 && $anthButtons.getButtonAuth('jmsh')"
					plain
					type="primary"
					size="mini"
					@click="audit"
					>审核
				</el-button>
			
			</div>
			<div>
				<div class="outer-row">
					<div
						style="
							font-size: 16px;
							font-weight: bolder;
							margin-bottom: 20px;
						"
					>
						用户信息
					</div>
					<div class="outer-rol">
						<div class="title">
							<span>基本信息</span>
						</div>
						<div class="outer-rol-info">
							<table class="desctable" cellspacing="0" cellpadding="0">
								<tr>
									<th>户主姓名</th><td>{{ userData.residentName }}</td>
									<th>户主电话</th><td>{{ userData.mobile }}</td>
									<th>用户分类</th><td>{{ userData.userTypeName }}</td>
								</tr>
								<tr>
									<th>身份证号</th><td>{{ userData.idnumber }}</td>
									<th>片区</th><td>{{ userData.addrName }}{{ userData.addrsName }}{{ userData.addrssName }}</td>
									<th>地址</th><td>{{ userData.address }}</td>
								</tr>
							</table>
						
						</div>
					</div>
					<div class="outer-rol">
						<div class="title">
							<span>安检信息</span>
						</div>
						<div class="outer-rol-info">
							<table class="desctable" cellspacing="0" cellpadding="0">
								<tr>
									<th>安检联系人</th><td>{{ userData.contactName }}</td>
									<th>联系电话</th><td>{{ userData.contactMobile }}</td>
									<th>是否为租户</th><td>{{
												 userData.islessee === null
												? ""
												: userData.islessee == 1
													? "是"
													: "否"	
											}}</td>
								</tr>
							
							</table>
						
						</div>
						
						
					</div>
					<div class="outer-rol">
						<div class="title">
							<span>表具信息</span>
						</div>
						<div class="outer-rol-info">
							<table class="desctable" cellspacing="0" cellpadding="0">
								<tr>
									<th>表编号</th><td>{{ userData.sheetSteel}}</td>
									<th v-if="userData.tableTypeName">燃气表类型</th><td v-if="userData.tableTypeName">{{ userData.tableTypeName }}</td>
									<th v-if="userData.tableDirectionName">表向</th><td v-if="userData.tableDirectionName">{{ userData.tableDirectionName }}</td>
								</tr>
								<tr>
									<th v-if="userData.tableVenderName">燃气表厂家</th><td v-if="userData.tableVenderName">{{ userData.tableVenderName }}</td>
									<th v-if="userData.tableTitles">表封号</th><td v-if="userData.tableTitles">{{ userData.tableTitles }}</td>
									<th v-if="userData.openDate">开户日期</th><td v-if="userData.openDate">{{ userData.openDate }}</td>
								</tr>
								<tr>
									<th v-if="userData.receiveStove">所接炉具</th><td v-if="userData.receiveStove">{{ userData.receiveStove }}</td>
									<th v-if="userData.remark">备注</th><td colspan="3" v-if="userData.remark">{{ userData.remark }}</td>
								</tr>

							</table>
						
						</div>
					
					</div>
				</div>
			</div>
		</div>
			<div  class="page-item">
				<div>
					<div
						style="
							font-size: 16px;
							font-weight: bolder;
							margin-bottom: 20px;
						"
					>
						安检记录
					</div>
					<div class="outer-rol">
						<div class="outer-rol-info">
							<el-table
								:data="tableData"
								border
								style="width: 100%"
                max-height="260"
								:header-cell-style="{background:'#F9FAFE'}"
							>
								<el-table-column
									align="center"
									prop="checkTime"
									label="检查时间"
									width="180"
								>
								</el-table-column>
								<el-table-column
									align="center"
									prop="inspectionTime"
									label="检查时长"
									width="180"
								>
								</el-table-column>
								<el-table-column
									align="center"
									prop="inspectorName"
									width="240"
									label="安检员"
								>
								</el-table-column>
								<el-table-column
									align="center"
									prop="safetyResults"
									label="安检结果"
								>
									<template slot-scope="{ row }">
										<p>
											{{
												getDictLabel(
													safetyResultsList,
													row.safetyResults,
													"id",
													"label"
												)
											}}
										</p>
									</template>
								</el-table-column>
								<el-table-column
									align="center"
									prop="problem1"
									label="问题登记"
								>
									<template slot-scope="{ row }">
										<p>
										<span v-if="row.safetyResults==2|| row.safetyResults==3"></span>
										<span v-else>
											{{
														getDictLabel(
															problemLists,
															row.problem1,
															"id",
															"label"
														)
													}}
										</span>
										</p>
									</template>
								</el-table-column>
								<el-table-column
									prop="address"
									align="center"
									label="详情"
								>
									<template slot-scope="{ row }">
										<el-button  type="primary" @click="onSafety(row)"
											v-if="row.safetyResults" size="mini">查看</el-button>
										
									</template>
								</el-table-column>
								<el-table-column
									align="center"
									prop="reviewResults"
									label="复查结果"
								>
									<template slot-scope="{ row }">
										<p>
											{{
												getDictLabel(
													reviewResultsList,
													row.reviewResults,
													"id",
													"label"
												)
											}}
										</p>
									</template>
								</el-table-column>
								<el-table-column
									align="center"
									prop="problem2"
									label="问题登记"
								>
									<template slot-scope="{ row }">
										<p>
										<span v-if="row.safetyResults==2|| row.safetyResults==3"></span>
										<span v-else>
											{{
												getDictLabel(
													problemLists,
													row.problem2,
													"id",
													"label"
												)
											}}
										</span>
								      </p>
									
									</template>
								</el-table-column>
								<el-table-column
									prop="address"
									align="center"
									label="详情"
								>
									<template slot-scope="{ row }">
										<el-button  type="primary" @click="onReview(row)"
											v-if="row.reviewResults" size="mini">查看</el-button>
										
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
			<div class="details-info" v-if="isShow">
				<div class="info-collect">
					<info-collect :data="itemData">
						<el-button
					slot="btn"
					plain
					type="primary"
					size="mini"
					@click="onExamine(1)"
					>单独查看
				</el-button>
				
					
					</info-collect>
				</div>
				<div class="issue-register">
					<issue-register :data="scuItemData">
					
						<el-button
							slot="btn"
							plain
							type="primary"
							size="mini"
							@click="onExamine(2)"
							>单独查看
						</el-button>
					</issue-register>
				</div>
			</div>
			<!-- 采集信息 -->
			<el-dialog
				:visible.sync="dialoginfo"
				width="40%"
				:close-on-click-modal="false"
			>
				<div style="height: 500px; overflow: auto">
					<info-collect :data="itemData"> </info-collect>
				</div>
			</el-dialog>
			<!-- 问题登记 -->
			<el-dialog
				:visible.sync="dialogissue"
				width="800px"
				:close-on-click-modal="false"
			>
				<div style="height: 500px; overflow: auto">
					<issue-register :data="scuItemData"> </issue-register>
				</div>
			</el-dialog>
			<!-- 查看图-->
			<el-dialog
				:visible.sync="dialogurlInfo"
				width="600px"
				:close-on-click-modal="false"
			>
				<div v-if="dialogurlInfo" style="height: 400px; overflow: auto">
					<url-info :data="urlData"></url-info>
				</div>
			</el-dialog>
			<!-- 正常安检详情 -->
			<el-dialog
				:visible.sync="dialogurlnormal"
				width="80%"
				:close-on-click-modal="false"
			>
				<div style="display: flex">
					<div style="flex:1.2;height:60vh;overflow-y:auto;">
						<info-collect :data="itemData">
						</info-collect>
					</div>
					<div style="flex:2;height:60vh;overflow-y:auto;border-left:1px solid #F1F1F1;border-right:1px solid #F1F1F1;padding:0 20px;">
						<issue-register :data="scuItemData">
						</issue-register>
					</div>
					<div style="flex:1;height:60vh;overflow-y:auto;margin-left:20px;">
						<user-validation
							:data="signatureUrl"
							:phot="photograph"
						></user-validation>
					</div>
				</div>
			</el-dialog>
			<!-- 正常复查详情 -->
			<el-dialog
				:visible.sync="dialogreview"
				width="60%"
				:close-on-click-modal="false"
			>
				<div style="display: flex;height: 600px;overflow:auto;">
					<issue-register :data="scuItemData" style="flex: 1">
					</issue-register>
          <div style="height: 100%;margin: 0 10px;border-right: 1px solid #ccc"></div>
					<user-validation
						:data="signatureUrl"
						:phot="photograph"
						style="flex: 0 0 300px"
					></user-validation>
				</div>
			</el-dialog>
			<el-dialog
				:visible.sync="dialogBatch"
				width="400px"
				:close-on-click-modal="false"
				@close="handleClose"
			>
				<el-form
					class="SearchBox"
					ref="Batchform"
					:model="Batchform"
					:rules="batchRule"
				>
					<el-form-item
						label="审核状态:"
						label-width="90px"
						prop="audit"
					>
						<el-radio-group v-model="Batchform.audit">
							<el-radio :label="1">审核</el-radio>
							<el-radio :label="2">驳回</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="说明:" label-width="90px">
						<el-input
							type="textarea"
							:rows="2"
							placeholder="请输入内容"
							maxlength="200"
							show-word-limit
							v-model="Batchform.explainState"
						>
						</el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button
						style="margin-right: 10px"
						@click="dialogBatch = false"
						>取消</el-button
					>
					<el-button type="primary" @click="submitBatch('Batchform')"
						>确定</el-button
					>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		getHistorySecurityUser,
		getSecurityUser,
		getdataCheckRecord,
		dataBatchAudit,
	} from "@/RequestPort/workorder/maintenRecord";
	import { getDictLabel } from "@/utils/common";
	import InfoCollect from "./infoCollect";
	import IssueRegister from "./issueRegister";
	import UserValidation from "./userValidation";

	import UrlInfo from "./urlInfo";
	export default {
		name: "civilDetails",
		components: {
			InfoCollect,
			IssueRegister,
			UrlInfo,
			UserValidation,
		},
		data() {
			return {
				getDictLabel,
				dataId: null,
				type: null,
				taskId: null,
				checkUserId: null,
				safetyResults: null,
				dialoginfo: false,
				dialogissue: false,
				dialogurlInfo: false,
				dialogurlnormal: false,
				dialogreview: false,
				dialogBatch: false,
				problemList: [
					{ label: "有问题未处理", id: "1" },
					{ label: "问题已处理", id: "2" },
					{ label: "无问题", id: "3" },
				],
				problemLists: [
					{ label: "无问题", id: "1" },
					{ label: "有问题未处理", id: "2" },
					{ label: "问题已处理", id: "3" },
				],
				safetyResultsList: [
					{ label: "正常安检", id: 1 },
					{ label: "到访不遇", id: 2 },
					{ label: "拒绝安检", id: 3 },
				],
				reviewResultsList: [
					{ label: "正常复查", id: 1 },
					{ label: "到访不遇", id: 2 },
				],
				auditStatusList: [
					{ label: "未审核", id: "1" },
					{ label: "已审核", id: "2" },
					{ label: "驳回", id: "3" },
				],
				batchRule: {
					audit: [{ required: true, message: "请选择", trigger: "blur" }],
				},
				// 审核表单数据
				Batchform: {
					audit: null,
					explainState: null,
				},
				tableData: [],
				detailsData: {},
				table: {},
				userData: {},
				scuItemData: [],
				itemData: [],
				urlData: [],
				signatureUrl: [],
				photograph: [],
				auditStatus: null,
			};
		},
		computed: {
			isShow() {

				let data = this.tableData.filter(el=>el.safetyResults == 1 || el.reviewResults == 1);
				return data.length>0
			},
		},
		created() {
			if (this.$route.query.dataId) {
				this.dataId = this.$route.query.dataId;
			}
			if (this.$route.query.type) {
				this.type = this.$route.query.type;
			}
			if (this.$route.query.taskId) {
				this.taskId = this.$route.query.taskId;
			}
			if (this.$route.query.residentId) {
				this.checkUserId = this.$route.query.residentId;
			}
			if (this.$route.query.safetyResults) {
				this.safetyResults = this.$route.query.safetyResults;
			}
			if (this.$route.query.auditStatus) {
				this.auditStatus = this.$route.query.auditStatus;
			}
			this.getDetails();
		},
		methods: {
			getDetails() {
				let { taskId, checkUserId, type, dataId } = this;
				getdataCheckRecord({ residentId: checkUserId, dataId }).then(
					(res) => {
						this.userData = res.data.arumUserPageVO;
						this.tableData = res.data.arumDataCheckRecordVOs;
					}
				);
				if (type == 1) {
					getHistorySecurityUser({ checkUserId, taskId,dataId }).then((res) => {
						this.detailsData = res.data;
						this.scuItemData = res.data.scuItem;
						this.table = res.data.table;
						this.itemData = res.data.item;
						this.auditStatus = res.data.auditStatus
						// console.log(res);
					});
				} else {
					getSecurityUser({ checkUserId, taskId,dataId }).then((res) => {
						this.detailsData = res.data;
						this.scuItemData = res.data.scuItem;
						this.table = res.data.table;
						this.itemData = res.data.item;
						this.auditStatus = res.data.auditStatus
						// console.log(res);
					});
				}
			},
			close() {
				this.$router.back(-1);
			},
			onExamine(type) {
				if (type == 1) {
					this.dialoginfo = true;
				} else {
					this.dialogissue = true;
				}
			},
			onReview(row) {
				if (row.reviewResults != 1) {
					this.urlData = row.url2 ? row.url2.split(",") : [];
					this.dialogurlInfo = true;
				} else {
					this.signatureUrl = row.userSignature2
						? row.userSignature2.split(",")
						: [];
					this.photograph = row.userUrl2 ? row.userUrl2.split(",") : [];
					this.dialogreview = true;
				}
			},
			onSafety(row) {
				if (row.safetyResults != 1) {
					this.urlData = row.url1.split(",");
					this.dialogurlInfo = true;
				} else {
					this.signatureUrl = row.userSignature1
						? row.userSignature1.split(",")
						: [];
					this.photograph = row.userUrl1 ? row.userUrl1.split(",") : [];
					this.dialogurlnormal = true;
				}
			},
			audit() {
				if (this.auditStatusList == 1) this.Batchform.audit = 1;
				this.dialogBatch = true;
			},
			// 关闭审核弹窗
			handleClose() {
				this.Batchform = { audit: null, explainState: null };
				this.dialogBatch = false;
			},
			// 提交审核
			submitBatch(form) {
				this.$refs[form].validate((valid) => {
					if (!valid) return false;
					let arr = [this.dataId];
					this.Batchform.dataIds = arr;
					dataBatchAudit(this.Batchform).then((res) => {
						if (res.code === 200) {
							this.$message.success(res.data);
							this.dialogBatch = false;
							// this.loadList();
							this.getDetails()
						} else {
							this.$message.error(res.data);
						}
					});
				});
			},
		},
	};
</script>

<style  scoped lang="less">
::v-deep{
	.el-dialog__header{padding-top:0;}
}
.page-item{background: #fff;padding:15px;margin-bottom:10px;border-radius: 6px;}
.desctable{
	width: 100%;
	line-height: 20px;
	border-collapse: collapse;
    border-spacing: 0;
	th{border: 1px solid #F0F2F5;padding:10px;text-align: right;width:125px;background: #F9F9F9;font-weight: normal;}
	td{border: 1px solid #F0F2F5;padding:10px;width:350px;}
}
	.pageDetails {
		padding: 10px;
		width: 100%;
		background: #f3f4f7;
		box-sizing: border-box;
		.page {
			// height: 100%;	
			height: calc(100vh - 92px);
			overflow: auto;	
			// position: relative;
			.create {
				width: 100%;
				margin-bottom:10px;
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				align-items: center;
				.create_size {
					font-size: 20px;
					font-weight: 500;
					color: #252700;
					margin-left: 20px;
				}
			}
			.title{
				font-size: 15px;font-weight: bolder;margin-bottom: 10px;margin-top:8px;
			}
			.title::before {
				content: "| ";
				font-weight: bold;
				color: #1082ff;margin-top: -2px;
			}
			.details-info {
				display: flex;
				margin-top: 10px;
				.info-collect {
					flex: 1;
					margin-right: 10px;background: #fff;padding:15px;border-radius: 6px;
				}
				.issue-register {
					flex: 2;background: #fff;padding:15px;border-radius: 6px;
				}
			}
			.outer-row {
				.outer-rol {
					padding-left: 15px;
					padding-bottom: 10px;
					.outer-rol-info {
						.el-col {
							padding-bottom: 10px;
						}
						.info {
							display: flex;
							.label {
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
		.auditStatus {
			position: absolute;
			width: 90px;
			right: 5px;
			top: 165px;
			padding: 10px 0;
			text-align: center;
			transform: rotate(45deg);
			margin-right: -12px;
      background: #fff;
      z-index: 9;
    //   &.reject{
    //     // background:#F56C6C ;
    //   }
		}
	}
</style>